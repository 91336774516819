
import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/Page.vue'

@Component({
  components: {
    Page,
  }
})
export default class HomeView extends Vue {

  get images(): string[] {
    const background = this.$jms('home.background');
    if (!background) {
      return [];
    }
    return [this.$jmsPath(background)];
  }
}
