import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ServicesView from '@/views/ServicesView.vue';
import PortfolioView from '../views/PortfolioView.vue'
import TestimonialsView from '@/views/TestimonialsView.vue';
import ResumeView from '../views/ResumeView.vue'
import FAQView from '@/views/FAQView.vue';
import ContactView from '../views/ContactView.vue'
import ErrorView from '../views/ErrorView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/services', name: 'services', component: ServicesView },
  { path: '/portfolio/:hash?', name: 'portfolio', component: PortfolioView, beforeEnter: (to: Route, from: Route, next: NavigationGuardNext<Vue<Record<string, any>>>) => {
      const items = Vue.prototype.$jms('portfolio.items') || [];
      if (!to.params.hash && items.length > 0) {
        return next({ name: 'portfolio', params: { hash: items[0].hash }});
      }
      if (items.find((item: any) => item.hash === to.params.hash)) {
        return next();
      }
      next({ name: 'error', params: { code: '404' } });
    } },
  { path: '/testimonials', name: 'testimonials', component: TestimonialsView },
  { path: '/resume', name: 'resume', component: ResumeView },
  { path: '/faq', name: 'faq', component: FAQView },
  { path: '/contact', name: 'contact', component: ContactView },
  { path: '/error/:code?', name: 'error', component: ErrorView, meta: { dark: true } },
  { path: '*', name: 'catchError', redirect: { name: 'error', params: { code: '404' } } },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
