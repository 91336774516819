
import { Component, Vue } from 'vue-property-decorator';
import Sticky from '@/components/Sticky.vue';
import Page from '@/components/Page.vue'

@Component({
  components: {
    Sticky,
    Page,
  }
})
export default class TestimonialsView extends Vue {

  loaded = true
  limit = 300

  get clients(): any[] {
    return this.$jms('testimonials.clients').filter((item: any) => item.logo);
  }

  get testimonials(): any[] {
    return this.$jms('testimonials.comments');
  }
}
