
import { Component, Vue } from 'vue-property-decorator'
import Sticky from '@/components/Sticky.vue'
import Page from '@/components/Page.vue'

@Component({
  components: {
    Sticky,
    Page,
  }
})
export default class FAQView extends Vue {

}
