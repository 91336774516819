
import { Vue, Component, Watch } from 'vue-property-decorator';
import Sidebar from '@/components/Sidebar.vue';
import CookieManager from '@/components/CookieManager.vue';
import Logo from '@/components/Logo.vue';
import { links } from '@/global';

@Component({
  components: {
    Sidebar,
    Logo,
    CookieManager,
  }
})
export default class HomeView extends Vue {
  routeName = null
  drawer = false
  links = links

  @Watch('$route.fullPath', { immediate: true })
  onRouteFullPathChange() {
    let title = (this.$i18n.t('title.' + this.$route.name) || 'Untitled') + ' - Danny Coulombe';
    Object.keys(this.$route.params).forEach(key => {
      title = (this.$i18n.t('routeParams.' + this.$route.name + '.' + key + '.' + this.$route.params[key]) || 'Untitled') + ' - ' + title;
    })
    document.title = title;
  }

  @Watch('$i18n.locale')
  onI18nLocaleChange(locale: string) {
    localStorage.setItem('locale', locale);
  }

  get flatToolbar(): boolean {
    return !this.$store.state.forceFlatToolbar && this.$vuetify.breakpoint.lgAndUp;
  }

  get locales(): Array<any> {
    return this.$i18n.availableLocales.map(locale => ({
      text: locale.substring(0, 2).toUpperCase(),
      value: locale,
    }))
  }

  onAppBarNavClick() {
    this.drawer = !this.drawer;
  }
}
