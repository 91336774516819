const links: Array<{
  key: string,
  icon: string | null,
  section: 'primary' | 'secondary',
  params?: {[key: string]: string}
}> = [
  { key: 'home', icon: 'mdi-presentation', section: 'primary' },
  { key: 'services', icon: 'mdi-room-service-outline', section: 'primary' },
  { key: 'portfolio', icon: 'mdi-view-carousel-outline', section: 'primary' },
  { key: 'testimonials', icon: 'mdi-comment-check-outline', section: 'primary' },
  { key: 'resume', icon: 'mdi-account-tie-outline', section: 'secondary' },
  { key: 'faq', icon: 'mdi-frequently-asked-questions', section: 'secondary' },
  { key: 'contact', icon: 'mdi-email-outline', section: 'primary' },
]

export {
  links,
}
