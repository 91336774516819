
import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/Page.vue'
import Sticky from '@/components/Sticky.vue'
import jsPDF from 'jspdf';
import moment from 'moment';
import '@/assets/fonts/Anton-Regular-normal'
import '@/assets/fonts/geomanist-regular-webfont-normal'

const month = (new Date().getMonth() + 1)
const now = new Date().getFullYear() + '-' + (month < 10 ? '0' + month : month);

@Component({
  components: {
    Page,
    Sticky,
  }
})
export default class ResumeView extends Vue {

  get jobs(): any[] {
    return this.$jms('resume.experiences');
  }

  get sticky(): boolean {
    return window.innerHeight > 700 && this.$vuetify.breakpoint.mdAndUp;
  }

  get totalMonthsOfExperience(): number {
    let months = 0;
    this.jobs.forEach(job => {
      months += this.getDurationInMonths(job.from, job.to);
    })
    return months;
  }

  get totalYearsOfExperience(): number {
    return Math.floor(this.totalMonthsOfExperience / 12);
  }

  get totalExperience(): string {
    const totalMonths = this.totalMonthsOfExperience;
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths - (years * 12);
    return (
      (years > 0 ? years + ' ' + this.$i18n.tc('resume.years', years) : '')
      + (months > 0 ? ' ' + months + ' ' + this.$i18n.tc('resume.months', months) : '')
    ).trim()
  }

  getDuration(from: string, to: string | undefined) {
    const totalMonths = this.getDurationInMonths(from, to);
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths - (years * 12);
    return (
      (years > 0 ? years + ' ' + this.$i18n.tc('resume.years', years) : '')
      + (months > 0 ? ' ' + months + ' ' + this.$i18n.tc('resume.months', months) : '')
    ).trim()
  }

  getDurationInMonths(from: string, to: string | undefined) {
    return moment(to || new Date()).diff(moment(from), "month");
  }

  onPrintClick() {
    window.print();
  }

  downloadAsPDF() {
    const doc = new jsPDF();
    const initialY = 20;
    const pageHeight = 280;
    let startY = initialY;

    function drawLine() {
      if (startY < pageHeight) {
        doc.setLineWidth(0.2);
        doc.setDrawColor(224, 224, 224);
        doc.line(14, startY, 192, startY);
        startY += 10;
      }
    }

    function checkStartY() {
      if (startY >= pageHeight) {
        doc.addPage();
        startY = initialY;
      }
    }

    function printImage(path: string, x: number, y: number, w: number, h: number, updateStartY = true) {
      checkStartY();

      const img = new Image();
      img.src = path;
      doc.addImage(img, 'png', x, y, w, h);

      if (updateStartY) {
        startY += h;
      }
    }

    function printText(str: string, size = 12, style = 'normal', color = '#000000', font = 'Verdana', x = 14) {
      checkStartY();

      doc.setFontSize(size);
      doc.setFont(font, style);
      doc.setTextColor(color);

      const splitTitle = doc.splitTextToSize(str, 192 - x);
      doc.text(splitTitle, x, startY);
      startY += ((size * splitTitle.length) / 2.5);
    }

    doc.setFillColor(48, 48,48);
    doc.rect(0, 0, 210, 40, 'F');
    doc.setFillColor(220, 229,255);
    doc.rect(0, 40, 210, 2, 'F');

    printImage(this.$jmsPath(this.$jms('resume.picture')), 14, startY, 40, 40, false);
    startY += 7.5;
    printText(this.$jms('resume.name'), 24, 'normal', '#FFFFFF', 'Anton-Regular', 65);
    startY -= 2;
    printText(this.$jms('resume.subtitle'), 14, 'normal', '#dce5f4', 'geomanist-regular-webfont', 65);
    startY += 10.5;

    // printText(this.$i18n.t('resume.about.title').toString(), 11, 'normal', undefined, undefined, 65);
    printText('+1 (514) 427-2525', 11, 'normal', undefined, undefined, 65);
    printText('danny@coulombe.dev', 11, 'normal', undefined, undefined, 65);
    printText('https://www.coulombe.dev', 11, 'normal', '#007BFF', undefined, 65);
    startY += 10;

    printText(this.$i18n.t('resume.about').toString(), 18, 'normal', undefined, 'geomanist-regular-webfont');
    printText(this.$jms('resume.about', { years: this.totalYearsOfExperience }).toString(), 11, 'normal');
    startY += 10;

    printText(this.$i18n.t('resume.experiences').toString(), 18, 'normal', undefined, 'geomanist-regular-webfont');
    startY += 5;

    this.jobs.forEach((job, jobIdx) => {
      if (jobIdx > 0) {
        startY += 3;
        drawLine();
        startY += 1;
      }

      checkStartY();
      printImage(this.$jmsPath(job.logo), 14, startY - 4, 5, 5, false);
      printText(job.position[this.$i18n.locale], 14, 'normal', '#333333', 'geomanist-regular-webfont', 22);
      startY -= 1;
      printText(job.company + ' - ' + this.$t('resume.positionType.' + job.type), 9, 'normal', '#333333', 'geomanist-regular-webfont', 22);
      startY += 2;
      printText(this.$i18n.t('resume.fromTo', {
        from: job.from.substring(0, 7),
        to: job.to && job.to.substring(0, 7) || this.$i18n.t('resume.now').toString().toLowerCase(),
      }).toString(), 9, 'normal', undefined, undefined, 22);
      startY -= 3.6;
      printText('(' + this.getDuration(job.from, job.to) + ')', 9, 'bold', undefined, undefined, 57.5);
      printText(job.location[this.$i18n.locale], 9, 'normal', '#666666', undefined, 22);
      startY += 3;
      printText(this.$i18n.t('resume.technologyList').toString() + ' ' + job.technologies, 11, 'normal', undefined, undefined, 22);
      startY += 3;
      job.tasks[this.$i18n.locale].toString().split('\n* ').forEach((task: string) => {
        printText('\u2022 ' + task.replace('* ', ''), 11, 'normal', undefined, undefined, 24);
      });
    })

    startY += 15;
    printText(this.$i18n.t('resume.education').toString(), 18, 'normal', undefined, 'geomanist-regular-webfont');
    this.$jms('resume.education').forEach((education: any, educationIdx: number) => {
      if (educationIdx > 0) {
        startY += 2;
      }
      printText(education.place[this.$i18n.locale], 11, 'normal');
      printText(education.diploma[this.$i18n.locale], 9, 'normal');
      printText(education.year, 9, 'normal', '#999999');
    });

    startY += 12;
    printText(this.$i18n.t('resume.languages').toString(), 18, 'normal', undefined, 'geomanist-regular-webfont');
    this.$jms('resume.languages').forEach((lang: any, langIdx: number) => {
      if (langIdx > 0) {
        startY += 2;
      }
      printText(lang.name[this.$i18n.locale], 9, 'bold');
      printText(this.$t('langLevel.' + lang.efficiency).toString(), 9, 'normal', '#666666');
    })

    startY += 5;
    drawLine();
    startY += 1;
    printText(this.$i18n.t('resume.generatedJsPdf').toString(), 9, 'italic', '#999999');

    doc.save('resume_danny-coulombe_' + this.$i18n.locale + '.pdf');
  }
}
