
import { Component, Vue, Watch } from 'vue-property-decorator';
import Sticky from '@/components/Sticky.vue';
import Page from '@/components/Page.vue'
import Laptop from '@/components/Laptop.vue'
import IPhone from '@/components/IPhone.vue'
import Vintage from '@/components/Vintage.vue'

let isMounted = false;

@Component({
  components: {
    Sticky,
    Page,
    Laptop,
    IPhone,
    Vintage,
  }
})
export default class PortfolioView extends Vue {

  isMounted = false
  videoLoaded = false

  @Watch('$route.fullPath', {
    immediate: true,
  })
  onCurrentItemIdxChange(newIdx: number, oldIdx: number) {
    this.$vuetify.goTo('html', {
      duration: 0
    });
    this.videoLoaded = false;
    setTimeout(() => this.videoLoaded = true, this.isMounted ? 0 : 100);
  }

  get items(): { hash: string, [key: string]: any }[] {
    return this.$jms('portfolio.items');
  }

  get currentItem(): any {
    return this.items[this.currentItemIndex];
  }

  get componentIs(): any {
    if (this.currentItem.presentation === 'laptop') {
      return 'Laptop';
    }
    if (this.currentItem.presentation === 'tv') {
      return 'Vintage';
    }
    if (this.currentItem.presentation === 'iphone') {
      return 'IPhone';
    }
    return 'Laptop'
  }

  get currentItemIndex(): any {
    return this.items.findIndex((item: any) => item.hash === this.$route.params.hash);
  }

  get canPlayVideo(): boolean {
    return this.videoLoaded && process.env.NODE_ENV !== 'development';
  }

  get prevItem(): any {
    const currentIdx = this.currentItemIndex;
    if (currentIdx === 0) {
      return this.items[this.items.length - 1];
    }
    return this.items[currentIdx - 1];
  }

  get nextItem(): any {
    const currentIdx = this.currentItemIndex;
    if (currentIdx === this.items.length - 1) {
      return this.items[0];
    }
    return this.items[currentIdx + 1];
  }

  get isImage(): boolean {
    return this.currentItem.file?.meta.type.includes('image');
  }

  get isVideo(): boolean {
    return this.currentItem.file?.meta.type.includes('video');
  }

  onKeyLeft() {
    const currentIdx = this.items.findIndex(item => item.hash === this.$route.params.hash);
    const newIdx = currentIdx === 0 ? this.items.length - 1 : currentIdx - 1;
    this.$router.push({ name: 'portfolio', params: { hash: this.items[newIdx].hash }})
  }

  onKeyRight() {
    const currentIdx = this.items.findIndex(item => item.hash === this.$route.params.hash);
    const newIdx = currentIdx === this.items.length - 1 ? 0 : currentIdx + 1;
    this.$router.push({ name: 'portfolio', params: { hash: this.items[newIdx].hash }})
  }

  onKeyDown(e: any) {
    if (e.key === 'ArrowLeft') {
      this.onKeyLeft();
    } else if (e.key === 'ArrowRight') {
      this.onKeyRight();
    }
  }

  mounted() {
    isMounted = true;
  }

  created() {
    if (!this.$route.params.hash) {
      this.$router.replace({ name: 'portfolio', params: { hash: this.items[0].hash } });
    }
    this.isMounted = isMounted;

    document.addEventListener('keydown', this.onKeyDown);
  }

  destroyed() {
    document.removeEventListener('keydown', this.onKeyDown);
  }
}
